<template>
  <div>
    <v-row class="px-4 pb-2" justify="end">
      <v-col class="align" cols="12">
        <label class="font-weight-bold mr-2 body-2">{{ $t(`label.status`) }}</label>
        <div class="d-inline-block mx-1 transaction-page-search-date-field-box">
          <app-form-field v-model.trim="searchCriteria.status" :items="isAffiliateMemberActiveStatusDdl" type="select" :change="getMemberReport"></app-form-field>
        </div>
      </v-col>
      <v-col class="align body-2" cols="12">
        <label>{{ `${$t(`label.totalMembers`)} = ` }}</label>
        <label class="font-weight-bold">{{ affiliateMembersPagination.totalItems }}</label>
      </v-col>
    </v-row>
    <v-data-table
      class="transaction-table px-4 "
      :headers="tableHeaders"
      :items="affiliateMembers.list"
      hide-default-footer
      disable-sort
      item-key="index"
      :no-data-text="$t(`message.noDataAvaliable`)"
    >
      <template v-slot:body="{ items, isMobile }">
        <tbody>
          <template v-if="!isMobile">
            <tr v-for="item in items" :key="item.index">
              <td>{{ item.memberCode }}</td>
              <td>{{ item.registerDate | longDate }}</td>
              <td>{{ $t(`affilateStatus.${item.isMemberActive}`) }}</td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="item in items" :key="item.index">
              <td>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.memberCode') }}</v-col>
                  <v-col class="text-right">{{ item.memberCode }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.registrationDate') }}</v-col>
                  <v-col class="text-right">{{ item.registerDate | longDate }}</v-col>
                </v-row>
                <v-row no-gutters class="py-2">
                  <v-col>{{ $t('label.status') }}</v-col>
                  <v-col class="text-right">{{ $t(`affilateStatus.${item.isMemberActive}`) }}</v-col>
                </v-row>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>

    <v-row class="px-4" justify="end">
      <v-col class="align body-2" cols="12">
        <label>{{ `${$t(`label.lastUpdatedDate`)}: ` }}</label>
        <label>{{ affiliateMembers.lastUpdatedDate | longDate }}</label>
      </v-col>
    </v-row>
    <v-row class="px-4" justify="end">
      <v-col class="align body-2" cols="12">
        <v-pagination v-model="currentPage" :length="totalPage" @input="changePage" :total-visible="paginationPageLimit"></v-pagination>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { AFFILIATE_MEMBER_LIST } from '@/store/affiliate.module'
import { locale, ddlHandler } from '@/util'
import { SHARED } from '@/constants/constants'
export default {
  name: 'affiliateMemberReport',
  data: () => ({
    isAffiliateMemberActiveStatusDdl: ddlHandler.getAffiliateMemberIsActiveStatus(),
    currentPage: 1,
    totalPage: 1,
    paginationPageLimit: 5,
    tableHeaders: [
      {
        text: locale.getMessage('label.memberCode'),
        align: 'left',
        class: 'body-2 font-weight-bold'
      },
      {
        text: locale.getMessage('label.registrationDate'),
        align: 'left',
        class: 'body-2 font-weight-bold'
      },
      {
        text: locale.getMessage('label.status'),
        align: 'left',
        class: 'body-2 font-weight-bold'
      }
    ],
    searchCriteria: {
      status: '',
      pageNumber: SHARED.DEFAULT_PAGE_NUMBER,
      pageSize: SHARED.DEFAULT_PAGE_SIZE
    }
  }),
  computed: {
    affiliateMembers() {
      return this.$store.state.affiliate.member
    },
    affiliateMembersPagination() {
      return this.$store.state.affiliate.member.pagination
    }
  },
  watch: {
    affiliateMembersPagination() {
      this.currentPage = this.$store.state.affiliate.member.pagination.pageNumber
      this.totalPage = this.$store.state.affiliate.member.pagination.lastPage
    }
  },
  created() {
    this.getMemberReport()
  },
  methods: {
    getMemberReport() {
      let obj = {
        size: this.searchCriteria.pageSize,
        page: this.searchCriteria.pageNumber,
        status: this.searchCriteria.status
      }
      this.$store.dispatch(`${AFFILIATE_MEMBER_LIST}`, { obj })
    },
    changePage(targetPage) {
      this.searchCriteria.pageNumber = targetPage
      this.getMemberReport()
    }
  }
}
</script>
